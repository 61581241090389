@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?cvemd3');
  src:
    url('../fonts/icomoon.eot?cvemd3#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?cvemd3') format('truetype'),
    url('../fonts/icomoon.woff?cvemd3') format('woff'),
    url('../fonts/icomoon.svg?cvemd3#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ico_file_outline:before {
  content: '\e982';
}
.icon-ico_img:before {
  content: '\e983';
}
.icon-ico_break:before {
  content: '\e97d';
}
.icon-ico_reject:before {
  content: '\e97f';
}
.icon-ico_send-back:before {
  content: '\e980';
}
.icon-ico_success:before {
  content: '\e981';
}
.icon-ico_mult_users:before {
  content: '\e97e';
}
.icon-ico_eye_off:before {
  content: '\e97a';
}
.icon-ico_responses_download:before {
  content: '\e97b';
}
.icon-ico_responses_upload:before {
  content: '\e97c';
}
.icon-ico_refresh:before {
  content: '\e979';
}
.icon-ico_certify:before {
  content: '\e978';
}
.icon-ico_Paste:before {
  content: '\e976';
}
.icon-ico_weblink:before {
  content: '\e977';
}
.icon-ico_clone:before {
  content: '\e975';
}
.icon-formula:before {
  content: '\e967';
}
.icon-data_mapping:before {
  content: '\e968';
}
.icon-add_column_after:before {
  content: '\e969';
}
.icon-add_column_before:before {
  content: '\e96a';
}
.icon-remove_column:before {
  content: '\e96b';
}
.icon-add_row_before:before {
  content: '\e96c';
}
.icon-add_row_after:before {
  content: '\e96d';
}
.icon-remove_row:before {
  content: '\e96e';
}
.icon-align_left:before {
  content: '\e96f';
}
.icon-align_center:before {
  content: '\e970';
}
.icon-align_right:before {
  content: '\e971';
}
.icon-text_bold:before {
  content: '\e972';
}
.icon-align_italic:before {
  content: '\e973';
}
.icon-align_underlaine:before {
  content: '\e974';
}
.icon-ico_date:before {
  content: '\e95d';
}
.icon-ico_form_checkbox:before {
  content: '\e95e';
}
.icon-ico_form_description:before {
  content: '\e95f';
}
.icon-ico_form_div:before {
  content: '\e960';
}
.icon-ico_form_drop:before {
  content: '\e961';
}
.icon-ico_form_inputnum:before {
  content: '\e962';
}
.icon-ico_form_inputtxt:before {
  content: '\e963';
}
.icon-ico_form_mail:before {
  content: '\e964';
}
.icon-ico_form_radio:before {
  content: '\e965';
}
.icon-ico_from_subject:before {
  content: '\e966';
}
.icon-chevron-double-down:before {
  content: '\e959';
}
.icon-chevron-double-left:before {
  content: '\e95a';
}
.icon-chevron-double-right:before {
  content: '\e95b';
}
.icon-chevron-double-up:before {
  content: '\e95c';
}
.icon-ico_dnd:before {
  content: '\e958';
}
.icon-ico_device_desktop:before {
  content: '\e955';
}
.icon-ico_device_mobile:before {
  content: '\e956';
}
.icon-ico_device_tablet:before {
  content: '\e957';
}
.icon-ico_file:before {
  content: '\e951';
}
.icon-ico_Excel:before {
  content: '\e950';
  color: #2f7743;
}
.icon-ico_PDF:before {
  content: '\e952';
  color: #e0362c;
}
.icon-ico_PPoint:before {
  content: '\e953';
  color: #f76900;
}
.icon-ico_Word:before {
  content: '\e954';
  color: #1777cf;
}
.icon-ico_new_window:before {
  content: '\e94f';
}
.icon-ico_error_toast:before {
  content: '\e949';
  color: #b9251c;
}
.icon-ico_info_toast:before {
  content: '\e94a';
  color: #747480;
}
.icon-ico_success_toast:before {
  content: '\e94b';
  color: #168736;
}
.icon-ico_waring_toast:before {
  content: '\e94c';
  color: #ff9831;
}
.icon-ico_design:before {
  content: '\e94d';
}
.icon-ico_unblock_user:before {
  content: '\e94e';
}
.icon-ico_anchor:before {
  content: '\e900';
}
.icon-ico_assigment:before {
  content: '\e901';
}
.icon-ico_condition:before {
  content: '\e902';
}
.icon-ico_decision:before {
  content: '\e903';
}
.icon-ico_list:before {
  content: '\e904';
}
.icon-ico_mail:before {
  content: '\e905';
}
.icon-ico_report:before {
  content: '\e906';
}
.icon-ico_statement:before {
  content: '\e907';
}
.icon-ico_table:before {
  content: '\e908';
}
.icon-ico_arrow_down:before {
  content: '\e909';
}
.icon-ico_arrow_left:before {
  content: '\e90a';
}
.icon-ico_arrow_right:before {
  content: '\e90b';
}
.icon-ico_arrow_up:before {
  content: '\e90c';
}
.icon-ico_attachment:before {
  content: '\e90d';
}
.icon-ico_benefits:before {
  content: '\e90e';
}
.icon-ico_block_user:before {
  content: '\e90f';
}
.icon-ico_check:before {
  content: '\e910';
}
.icon-ico_close:before {
  content: '\e911';
}
.icon-ico_copy:before {
  content: '\e912';
}
.icon-ico_delete:before {
  content: '\e913';
}
.icon-ico_download:before {
  content: '\e914';
}
.icon-ico_edit:before {
  content: '\e915';
}
.icon-ico_Expand:before {
  content: '\e916';
}
.icon-ico_ExpireVersion:before {
  content: '\e917';
}
.icon-ico_export_excel:before {
  content: '\e918';
}
.icon-ico_eye:before {
  content: '\e919';
}
.icon-ico_favorites-Yes:before {
  content: '\e91a';
}
.icon-ico_favorites:before {
  content: '\e91b';
}
.icon-ico_filters:before {
  content: '\e91c';
}
.icon-ico_grant_access:before {
  content: '\e91d';
}
.icon-ico_help:before {
  content: '\e91e';
}
.icon-ico_hideMap:before {
  content: '\e91f';
}
.icon-ico_import_excel:before {
  content: '\e920';
}
.icon-ico_lock:before {
  content: '\e921';
}
.icon-ico_logout:before {
  content: '\e922';
}
.icon-ico_mail1:before {
  content: '\e923';
}
.icon-ico_more:before {
  content: '\e924';
}
.icon-ico_notifications:before {
  content: '\e925';
}
.icon-ico_notNeeded:before {
  content: '\e926';
}
.icon-ico_plus:before {
  content: '\e927';
}
.icon-ico_print:before {
  content: '\e928';
}
.icon-ico_publish:before {
  content: '\e929';
}
.icon-ico_redo:before {
  content: '\e92a';
}
.icon-ico_RenewVersion:before {
  content: '\e92b';
}
.icon-ico_restore:before {
  content: '\e92c';
}
.icon-ico_review:before {
  content: '\e92d';
}
.icon-ico_save:before {
  content: '\e92e';
}
.icon-ico_search:before {
  content: '\e92f';
}
.icon-ico_send:before {
  content: '\e930';
}
.icon-ico_settings:before {
  content: '\e931';
}
.icon-ico_share:before {
  content: '\e932';
}
.icon-ico_shevron_down_big:before {
  content: '\e933';
}
.icon-ico_shevron_down:before {
  content: '\e934';
}
.icon-ico_shevron_left_big:before {
  content: '\e935';
}
.icon-ico_shevron_left:before {
  content: '\e936';
}
.icon-ico_shevron_right_big:before {
  content: '\e937';
}
.icon-ico_shevron_right:before {
  content: '\e938';
}
.icon-ico_shevron_up_big:before {
  content: '\e939';
}
.icon-ico_shevron_up:before {
  content: '\e93a';
}
.icon-ico_showMap:before {
  content: '\e93b';
}
.icon-ico_sort:before {
  content: '\e93c';
}
.icon-ico_syncAlert:before {
  content: '\e93d';
}
.icon-ico_tooltip:before {
  content: '\e93e';
}
.icon-ico_triangle_down:before {
  content: '\e93f';
}
.icon-ico_triangle_left:before {
  content: '\e940';
}
.icon-ico_triangle_right:before {
  content: '\e941';
}
.icon-ico_triangle_up:before {
  content: '\e942';
}
.icon-ico_undo:before {
  content: '\e943';
}
.icon-ico_unlock:before {
  content: '\e944';
}
.icon-ico_upload:before {
  content: '\e945';
}
.icon-ico_user:before {
  content: '\e946';
}
.icon-ico_zoomIn:before {
  content: '\e947';
}
.icon-ico_zoomOut:before {
  content: '\e948';
}
